import { useState } from "react";
import { Grid, TextField, Typography, Box, Button, Alert } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

/* =====[API]===== */
import { Axios } from "../../services/api";
/* =============== */

/* =====[Styles]===== */
import styles from "./form.module.css";
/* ================= */

/* =====[Icons]===== */
import SendIcon from '@mui/icons-material/Send';
/* ================= */

const Form = () => {
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        company: "",
        contact_number: "",
        email: "",
        message: "",
        join_newsletter: "",
        action: "Contact",
        sub_action: "saveNew",
        table: "contact_form_response"
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    const handleSubmit = (event) =>{
        event.preventDefault();

        Axios.post('capture_request.php', JSON.stringify(formData))
        .then(function(response){
            if(response){
                document.getElementById("responseMSG").style.display = "block";

                setTimeout(function(){
                    window.location.reload();
                },2000);
            }
        });
    }

    return (
        <Grid className={styles.backgroundImage}>
            <Grid
                className={styles.formContent}
                sx={{ 
                    width: {
                        xs: "100%",
                        md: "50%",
                        lg: "50%"
                    },
                    height: {
                        xs: "70vh",
                        md: "91vh",
                        lg: "91vh"
                    }
                }}
            >
                <Typography 
                    variant="h5" 
                    sx={{
                        textAlign: "center", 
                        textTransform: "uppercase"
                    }}
                >
                    Get In Touch
                </Typography>
                <Alert variant="outlined" severity="success" id="responseMSG" sx={{ display: "none", width: "auto"}}>
                    Great! - We'll get back to ASAP.
                </Alert>
                <form name="contactForm" onSubmit={handleSubmit}>
                    <Box m={2} >
                        <TextField 
                            required
                            id ="first_name"
                            name="first_name"
                            label="First Name"
                            className="blockDisplay" 
                            onChange={onChangeInput}
                            sx={{
                                margin: "5px 2px",
                                width: {
                                    xs: "100%",
                                    md: "48%",
                                    lg: "48%"
                                }
                            }}
                        />
                        <TextField 
                            required
                            id = "last_name"
                            name="last_name"
                            label = "Last Name"
                            className="blockDisplay"
                            onChange={onChangeInput}
                            sx={{
                                margin: "5px 2px",
                                width: {
                                    xs: "100%",
                                    md: "48%",
                                    lg: "48%"
                                }
                            }}
                        />
                    </Box>
                    <Box
                        m={2}
                    >
                        <TextField 
                            required
                            id = "company"
                            name="company"
                            label = "Company"
                            onChange={onChangeInput}
                            sx={{
                                margin: "5px 2px",
                                width: {
                                    xs: "100%",
                                    md: "48%",
                                    lg: "48%"
                                }
                            }}
                        />
                        <TextField 
                            id = "contact_number"
                            name="contact_number"
                            label = "Contact #"
                            className="blockDisplay"
                            onChange={onChangeInput}
                            sx={{
                                margin: "5px 2px",
                                width: {
                                    xs: "100%",
                                    md: "48%",
                                    lg: "48%"
                                }
                            }}
                        />
                    </Box>
                    <Box m={2}>
                        <TextField 
                            required
                            id = "email"
                            name="email"
                            label = "Email"
                            onChange={onChangeInput}
                            sx={{
                                margin: "5px",
                                width: {
                                    xs: "100%",
                                    md: "97%",
                                    lg: "97%"
                                }
                            }}
                        />
                    </Box>
                    <Box m={2}>
                        <TextField
                            required
                            id="message"
                            name="message"
                            label="Message"
                            multiline
                            rows={4}
                            className="blockDisplay"
                            onChange={onChangeInput}
                            sx={{
                                margin: "5px",
                                width: {
                                    xs: "100%",
                                    md: "97%",
                                    lg: "97%"
                                }
                            }}
                        />
                    </Box>
                    <Box m={2}>
                        <InputLabel id="mailingListLable" >Join Newsletter?</InputLabel>
                        <Select
                                labelId="mailingListLable"
                                id="join_newsletter"
                                name="join_newsletter"
                                label="Join Newsletter?"
                                onChange={onChangeInput}
                                sx={{
                                    margin: "5px",
                                    width: {
                                        xs: "100%",
                                        md: "45%",
                                        lg: "45%"
                                    },
                                }}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                        <Button 
                            type="submit"
                            variant="outlined" 
                            startIcon={<SendIcon />}
                            sx={{
                                height: {
                                    lg: "55px",
                                    md: "55px"
                                },
                                margin: "5px",
                                marginRight:{
                                    lg: "13px",
                                    md: "13px"
                                },
                                width: {
                                    xs: "100%",
                                    md: "45%",
                                    lg: "45%"
                                },
                                float: {
                                    lg: "right",
                                    md: "right"
                                }
                            }}
                        >
                            Send
                        </Button>
                    </Box>
                </form>
            </Grid>
        </Grid>
    );
}

export default Form;