import { Grid, Box, Typography} from '@mui/material'; 
import { useRef } from 'react';

/* =====[Styles]===== */
import styles from "./landingpage.module.css";
/* ================= */

/* =====[Components]===== */
import HeroSection from "../../components/HeroSection";
import Form from "../../components/Form";
import Footer from '../../components/Footer';
/* ===================== */

const LandingPage = () => {
    const contactFormSectoin = useRef(null);

    const handleClick = () => {
        contactFormSectoin.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Grid>
            <HeroSection click={handleClick} />
            <Typography 
                variant="h3" 
                sx={{
                    textAlign: "center", 
                    textTransform: "uppercase", 
                    fontWeight: "450",
                    marginTop: "2rem"
                }}
                ref={contactFormSectoin} 
            >
                Our Mission
            </Typography>
            <Box className={styles.gridContainer}>
                <Box 
                    sx={{ 
                        marginTop: {
                            xs: "100px",
                            md: "0px",
                            lg: "0px%"
                        },
                        width: {
                            xs: "100%",
                            md: "50%",
                            lg: "50%"
                        }
                    }}
                >
                    <Form />
                </Box>
                <Box 
                    sx={{ 
                        marginTop: {
                            xs: "70px"
                        },
                        width: {
                            xs: "100%",
                            md: "50%",
                            lg: "50%"
                        }
                    }}
                >
                    <Footer />
                </Box>
            </Box>
        </Grid>
        
    );
}

export default LandingPage;