import { useState, useEffect } from "react";
import { Typography } from "@mui/material";

/* =====[Styles]===== */
import styles from "./countdown.module.css"
/* ================= */

const Countdown = () => {
    const targetDate = new Date("2023-11-01T00:00:00").getTime();
    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

    function calculateRemainingTime() {
        const currentDate = new Date().getTime();
        const timeLeft = targetDate - currentDate;
        return timeLeft > 0 ? timeLeft : 0;
    }

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setRemainingTime(calculateRemainingTime());
        }, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, []);

    const formatTime = (time) => {
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    return(
        <Typography 
            className={styles.text}
            variant="h5"
            id="countdown"
        >
            {formatTime(remainingTime)}
        </Typography>
    );
}

export default Countdown;