import './App.css';
import { Grid } from '@mui/material';
import LandingPage from './pages/LandingPage';

function App() {
  return (
      <Grid>
        <LandingPage />
      </Grid>
  );
}

export default App;
