import { Grid, Box, Button, Typography, Link } from "@mui/material";
import { useEffect, useState } from "react";

/* =====[Assests]===== */
import Logo from "../../assests/Logo_transparent.png";
/* =================== */

/* =====[Styles]===== */
import styles from "./herosection.module.css"
/* ================= */

/* =====[Icons]===== */
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
/* ================= */

const HeroSection = (props) => {
    const handleClick = props.click;

    const targetDate = new Date("2023-11-01T00:00:00").getTime();
    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

    function calculateRemainingTime() {
        const currentDate = new Date().getTime();
        const timeLeft = targetDate - currentDate;
        return timeLeft > 0 ? timeLeft : 0;
    }

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setRemainingTime(calculateRemainingTime());
        }, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, []);

    const formatTime = (time) => {
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <Grid 
            className={styles.mainContent} 
            sx={{
                height: {
                    md: "100vh",
                    lg: "100vh"
                }
            }}
        >
            <Box className={styles.contentContainer}>
                <img src={Logo} className={styles.Logo}/>    
                <Typography 
                    className={styles.text}
                    variant="h5"
                    id="countdown"
                >
                    {formatTime(remainingTime)}
                </Typography>
                <Box mt={1}>
                    <Link href="https://www.linkedin.com/company/visagie-and-co/" target="_blank"><LinkedInIcon className={styles.socialIcons} /></Link>
                    <Link href="https://www.facebook.com/visagieandco" target="_blank"><FacebookIcon  className={styles.socialIcons} /></Link>
                    <Link href="https://www.instagram.com/visagieandco/" target="_blank"><InstagramIcon className={styles.socialIcons} /></Link>
                    <Link href="https://twitter.com/visagieandco" target="_blank"><TwitterIcon  className={styles.socialIcons} /></Link>
                </Box>
            </Box>
            <Box className={styles.buttonContainer}>   
                <Box 
                    className={styles.buttonAnimation}
                    sx={{
                        border: "1px solid #C8B293",
                        borderRadius: {
                            xs: "20px"
                        },
                        height: {
                            xs: "40px"
                        }
                    }}
                >
                    <Button
                        onClick={() => handleClick()}
                        variant="text"
                        sx={{
                            fontSize: {
                                xs: "16px"
                            },
                            color: "#C8B293"
                            
                        }}
                    >
                        <KeyboardDoubleArrowDownIcon 
                            sx={{
                                color: "#C8B293"
                            }}
                        />
                        Contact
                        <KeyboardDoubleArrowDownIcon 
                            sx={{
                                color: "#C8B293"
                            }}
                        />
                    </Button>
                </Box>
            </Box>
            <Box >
                <Typography sx={{ color: "#FFF", textAlign: "center", textTransform: "uppercase", fontWeight: "450" }} variant="h3">Your Vision</Typography>
            </Box>
        </Grid>
    );
}

export default HeroSection;