import { Grid, Box, Link} from "@mui/material";

/* =====[Assets]===== */
import Logo from "../../assests/decon_logo.png";
/* ================== */

/* =====[Styles]===== */
import styles from "./footer.module.css"
/* ================= */

/* =====[Components]===== */
import Countdown from "../Countdown";
/* ====================== */

/* =====[Icons]===== */
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
/* ================= */

const Footer = () => {
    return(
        <Grid className={styles.footerContainer}>
            <Box>
                <img 
                    src={Logo} 
                    className={styles.deconLogo}
                />
            </Box>
            <Countdown />
            <Box>  
                <Link href="https://www.linkedin.com/company/visagie-and-co/" target="_blank"><LinkedInIcon className={styles.socialIcons} /></Link>
                <Link href="https://www.facebook.com/visagieandco" target="_blank"><FacebookIcon  className={styles.socialIcons} /></Link>
                <Link href="https://www.instagram.com/visagieandco/" target="_blank"><InstagramIcon className={styles.socialIcons} /></Link>
                <Link href="https://twitter.com/visagieandco" target="_blank"><TwitterIcon  className={styles.socialIcons} /></Link>
            </Box>
            
        </Grid>
    );
}

export default Footer;